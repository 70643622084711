<template>
  <div class="row no-gutters">
    <div class="col-12">
      <div class="row no-gutters">
        <div class="col-10 offset-1 col-lg-12 offset-lg-0">
          <div class="m-card text-center">
            <h1 class="o-heading o-heading--xl">
              {{ $t("appCheck.title") }}
            </h1>
            <p>
              {{ $t("appCheck.subtitle") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-10 offset-1 col-lg-4 offset-lg-4 pt-5 ">
      <div class="m-card ">
        <div class="m-card__img">
          <b-img-lazy
            src="../../assets/appCheck.svg"
            alt="operatore"
          ></b-img-lazy>
        </div>
        <div class="m-card__text text-center">
          <p>
            {{ $t("appCheck.message_0_"+hasApp) }}
          </p>
          <p>
            <strong> {{ $t("appCheck.message_1_"+hasApp) }}</strong>
          </p>
          <p>{{ $t("appCheck.message_2_"+hasApp) }}</p>
        </div>
        <div class="m-card__text text-center">
          <p>
            {{ $t("appCheck.message_3_"+hasApp) }}
          </p>
        </div>
      </div>
    </div>

    <div  class="col-10 offset-1 col-lg-4 offset-lg-4 pt-4 text-center">
      <a  @click="openSms()" class="text-primary" :style="{ cursor: 'pointer'}"
        ><strong>{{ $t("appCheck.action_"+hasApp) }}</strong></a
      >
    </div>

    <div class="col-10 offset-1 col-lg-4 offset-lg-4 pt-4 text-center" v-if="false">
      <b-alert variant="warning" id="otp_expired" v-model="otpExpired" >
       <p>{{ $t("appCheck.otp_expired") }}</p>
       <b-button @click="reloadPage()" variant="primary">
         {{ $t("appCheck.request_new_otp") }}
       </b-button>
      </b-alert>
    </div>

  </div>
</template>

<script>
import store from "@/store";
import apiAxios from "@/libs/apiAxios";
import router from "@/router";

export default {
  name: "AppCheckByApp",

  props:{
    service:{
      default:"fatref"
    },
    target:{
      default:""
    },
    fullPage:{
      default:true
    },
    enableApp:{
      default:true
    },
    mdl:{
      default:false
    },
    hasApp:{
      default: false
    }
  },
  created() {
    console.log("Created");
    this.hasApp=this.calcHasApp();
    if(this.hasApp)
      this.queryService();
  },
  data() {
    return {
      passwordHide: "*********",
      pollInterval:null,
      pollDone:false,
      otp:null,
      data: [],
      otpExpired: false,
    };
  },
  computed: {
    myService: function () {
      switch(this.service)
      {
        case "referti":
        case "fatture":
          return "fatref";
        case "mdl":
          return "mdl";
        default:
          return "";
      }

    },
    userdata: function () {
      return store.getters['dateUser/UserData'];
    },
  },


  methods: {

    reloadPage() {
      router.go();
    },

    calcHasApp () {

      console.log("Calc App");
      const sess=sessionStorage.getItem("userdata");
      if (sess==null)
        return false;
      const userdata=JSON.parse(sess);

      if (userdata == null)
        return false;

      if (userdata.services == null)
        return false;


      //console.log(userdata);
      for(let i=0;i<userdata.services.length;i++)
      {
        if (userdata.services[i].authentication==='OTP')
          return userdata.services[i].pinAuth;
      }

      return false;
    },

    async queryService(){
      const data={
        service:this.service,
        media:"pin"
      }
      let otp=null;

      await apiAxios.post("user/queryService",data)
          .then(response => {
            otp=response.data.QueryServiceResult;
            this.queryCallback(otp);
          })
          .catch(error => {console.log(error);this.fail("Servizio non permesso")})


    },
    openSms()
    {
      router.push('confermaCodSms?service='+this.service+'&target='+this.target+(this.mdl==true?'&mdl=true':''));
    },
    queryCallback:function(data)
    {
      console.log(data);
      this.otp=data.otp;



      if (this.otp==null)
      {
        this.error="Invalid OTP";
        this.fail();
      }
      else {
        this.otp=data.otp;

      }
    },
    async checkOtp() {
      //console.log("Check OTP:"+this.otp);
      if (this.otp==null)
        return;
      await apiAxios.get("user/otpPoll/"+this.otp)
        .then(response=> {
          const res=response.data.OtpPollResult;
          if (res.state!==0)
          {
            clearInterval(this.pollInterval);
            this.otp=null;
            if (res.state===1)
            {
              if ('service_token' in res.payload) {
                sessionStorage.setItem("security_token", res.payload.service_token);
              }
              this.success(res.payload);
            }
            else {
              this.fail();
            }
          }

        })
        .catch(errore=>{
          console.log(errore)
        });
    },
    polling:function()
    {
      if (this.$route.path!=='/confermaAccessoApp')
      {
        console.log("Polling DIE:"+this.$route.path);
        clearInterval(this.pollInterval);
        return;
      }

      this.checkOtp();

    },
    fail()
    {
      this.stage=0;
      switch(this.service)
      {
        case "referti":
          router.push("/?fail="+this.error);
          return;
        case "fatture":
          router.push("/?fail="+this.error);
          return;

        default:
          //this.$emit("failed")
          //return "";
          this.otpExpired = true;
      }
    },
    success(payload)
    {
      console.log(payload);

      router.push(this.target);

    },
  },
 mounted() {


    if(!this.pollDone ) {
      this.pollInterval = setInterval(this.polling, 5000) //save reference to the interval
      setTimeout(() => {clearInterval(this.pollInterval)}, 12000000) //stop polling after an hour
    }

  }
};
</script>
